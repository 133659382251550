import React from "react";
import _ from "lodash";
import styles from "./SelectObjectives.module.scss";
import { TextField, MenuItem, Checkbox, ListItemText, Typography } from "@material-ui/core";
import PlanPill from "../PlanPill/PlanPill";

const NewSelectObjectives = ({
  name,
  objectives,
  category,
  multiple,
  plansOrder = [],
  showEmpty = false,
  helperText,
  formik,
  disabled = false,
}) => {
  const { values: formikValues, errors, touched, getFieldProps } = formik;

  let filter = [],
    tiedIn = "";
  if (category === "3 year" || category === "rock") {
    filter = ["1 year"];
    tiedIn = "1 Year";
  } else if (category === "1 year") {
    filter = ["3 year"];
    tiedIn = "3 Year";
  } else {
    filter = ["3 year", "1 year"];
  }

  const isTouched = _.get(touched, name);
  const error = _.get(errors, name);

  const hasError = Boolean(isTouched && error);
  const helperTxt = hasError ? error : helperText;

  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      margin="normal"
      label={`Tied In ${tiedIn} Objective${multiple ? "s" : ""}`}
      {...getFieldProps(name)}
      SelectProps={{
        multiple,
        ...(multiple
          ? {
              renderValue: (selected) => (
                <Typography variant="inherit" noWrap>
                  {`${selected.length} Objective(s) Selected`}
                </Typography>
              ),
            }
          : {}),
      }}
      error={hasError}
      helperText={helperTxt}
      disabled={disabled}
    >
      {showEmpty && (
        <MenuItem value={null} className={styles.select}>
          <ListItemText>No objective tie in</ListItemText>
        </MenuItem>
      )}
      {objectives &&
        _.sortBy(objectives, [
          function (obj) {
            // this preliminary sorting step is required if the list of objectives includes multiple plans
            return plansOrder.indexOf(_.get(obj, "plan.sharedPlanId"));
          },
          "number",
        ]).map(({ id, value, category, number, plan }) => {
          if (!filter.includes(category)) return null;
          return (
            <MenuItem key={id} value={id} className={styles.select}>
              <ListItemText>
                {!_.isNil(plan) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
                {number}. {value}
              </ListItemText>
            </MenuItem>
          );
        })}
    </TextField>
  );
};

export default NewSelectObjectives;
