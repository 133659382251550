import React, { useEffect, useState, useRef, useMemo } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";

function useLLM({ snack, open, suggestionsLoading }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const [llmSuggestions, setLlmSuggestions] = useState([]);
  const [llmSuggestionsListOpen, setLlmSuggestionsListOpen] = useState(false);
  const [llmSettingsOpen, setLlmSettingsOpen] = useState("");

  const [canUndo, setCanUndo] = useState(false);

  const abortControllerRef = useRef(null);

  const [updateLlmSettings] = useMutation(UPDATE_LLM_SETTINGS);

  const { data: llmSettingsData, refetch: llmSettingsRefetch, loading: llmSettingsLoading } = useQuery(GET_ALL_LLM_SETTINGS);

  const [rephraseText, { data: rephraseTextData, loading: rephraseTextLoading, error: rephraseTextError }] = useLazyQuery(REPHRASE_TEXT, {
    fetchPolicy: "network-only", // Ensures data is always fetched from the server
  });

  const llmSettingsByPurpose = useMemo(() => {
    if (_.isNil(llmSettingsData)) return {};

    const llmSettings = _.get(llmSettingsData, "allLLMSettings", []);
    return _.groupBy(llmSettings, "purpose");
  }, [llmSettingsData]);

  const handleOpenAdminMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAdminMenu = () => {
    setAnchorEl(null);
  };

  const handleCancelBrainstorm = () => {
    if (suggestionsLoading) setLlmSuggestions([]);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null; // Reset the ref for future use
    }
  };

  const handleSaveLLMSettings = async (newLLMSettings = {}) => {
    const { type, globalSystemDescription, globalUserPrompt, systemDescription, userPrompt, useGlobal, systemObjects } = newLLMSettings;
    const llmSettingsForType = _.find(_.get(llmSettingsByPurpose, llmSettingsOpen, []), ["type", type]);

    try {
      const res = await updateLlmSettings({
        variables: {
          id: _.get(llmSettingsForType, "id"),
          globalSystemDescription,
          globalUserPrompt,
          systemDescription,
          userPrompt,
          useGlobal,
          systemObjects,
        },
      });

      if (res) {
        snack(`Updated ${_.lowerCase(type)} LLM settings (${llmSettingsOpen})`);
        // setLlmSettingsOpen(""); // close the dialog
      }
    } catch (err) {
      snack(`Failed to update ${_.lowerCase(type)} LLM settings (${llmSettingsOpen})`, "error");
    }
  };

  useEffect(() => {
    if (!open) {
      setCanUndo(false);
    }
  }, [open]);

  return {
    anchorEl,
    setAnchorEl,
    llmSuggestions,
    setLlmSuggestions,
    llmSuggestionsListOpen,
    setLlmSuggestionsListOpen,
    llmSettingsOpen,
    setLlmSettingsOpen,
    canUndo,
    setCanUndo,
    abortControllerRef,
    updateLlmSettings,
    llmSettingsData,
    llmSettingsRefetch,
    llmSettingsLoading,
    rephraseText,
    rephraseTextData,
    rephraseTextLoading,
    llmSettingsByPurpose,
    handleOpenAdminMenu,
    handleCloseAdminMenu,
    handleCancelBrainstorm,
    handleSaveLLMSettings,
  };
}

export default useLLM;

const REPHRASE_TEXT = gql`
  query ($text: String!) {
    rephrase(text: $text)
  }
`;

const GET_ALL_LLM_SETTINGS = gql`
  query {
    allLLMSettings {
      id
      purpose
      type
      systemDescription
      userPrompt
      useGlobal {
        systemDescription
        userPrompt
      }
      systemObjects
    }
  }
`;

const UPDATE_LLM_SETTINGS = gql`
  mutation (
    $id: ID!
    $globalSystemDescription: String!
    $globalUserPrompt: String!
    $systemDescription: String!
    $userPrompt: String!
    $useGlobal: UseGlobalInput!
    $systemObjects: [String!]
  ) {
    updateLLMSettings(
      id: $id
      globalSystemDescription: $globalSystemDescription
      globalUserPrompt: $globalUserPrompt
      systemDescription: $systemDescription
      userPrompt: $userPrompt
      useGlobal: $useGlobal
      systemObjects: $systemObjects
    ) {
      id
      purpose
      type
      systemDescription
      userPrompt
      useGlobal {
        systemDescription
        userPrompt
      }
      systemObjects
    }
  }
`;
