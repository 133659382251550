import { FormControl } from "@material-ui/core";
import styles from "./OutlinedSection.module.scss";
import React from "react";

const OutlinedSection = (props) => {
  const { children, title, error, ...otherProps } = props;

  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error} className={styles.container} {...otherProps}>
      <div className={styles.inputLabel}>{title}</div>
      {children}
    </FormControl>
  );
};

export default OutlinedSection;
